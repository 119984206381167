import React, { useState, useEffect } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import Select, { StylesConfig } from "react-select";
interface OptionType {
  value: string | null;
  label: string | null;
}

export const Header = () => {
  const options = [
    { value: "en", label: "English" },
    { value: "vi", label: "Việt Nam" },
  ];
  const customStyles: StylesConfig<OptionType, false> = {
    control: (provided) => ({
      ...provided,

      "@media screen and (min-width: 481px)": {
        maxWidth: "205px",
      },
      "@media screen and (max-width: 480px)": {
        maxWidth: "190px",
      },
      borderRadius: 4,
      border: "1px solid #000000",
      boxShadow: "none",
      backgroundColor: "transparent",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: 16,
      color: "#2d3748",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#4a5568",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "100%",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 16,
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      display: "none", // Change color of the loading spinner
    }),
  };
  const findCurrentOption = (lang: string) => {
    return options.find((option) => option.value === lang) || options[0]; // Fallback to first option
  };
  const { language, changeLanguage } = useI18next();
  const [selectedOption, setSelectedOption] = useState(
    findCurrentOption(language)
  );

  useEffect(() => {
    // Update selected option if the language changes elsewhere in the app
    setSelectedOption(findCurrentOption(language));
  }, [language]);
  const handleLanguageChange = (optionValue: any) => {
    setSelectedOption(optionValue); // Update the selected option
    const selectedLanguage = optionValue.value;
    changeLanguage(selectedLanguage);
  };
  return (
    <div style={{ margin: 0, width: "150px", height: "40px" }}>
      <Select
        className="text-[#000000]"
        styles={customStyles}
        options={options}
        defaultValue={options[0]}
        value={selectedOption}
        onChange={(optionValue) => handleLanguageChange(optionValue)}
      />
    </div>
  );
};
